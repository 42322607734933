import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Moregames from './games/Moregames';
import Comments from './Comments';
import '../static/css/tilesets.css';
import GoogleAd from './GoogleAd';

import general from '../static/json/general.json';

function bigName(gameName) {
	const names = {
        	"guesser": "GUESS THE NUMBER"
        };
        if(gameName in names) {
        	return names[gameName];
        } else {
			return gameName.toUpperCase();
		}
}


function Tileset({game, tileset, name, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	function bestScore() {
		var best = localStorage.getItem(game + "-" + tileset + "-best");
		return best;
	}

	function getUrl({game, tileset}) {
		if(game === "guesser") {
	        return ("/" + detectedLanguage + "/" + tileset);
	    } else {
	        return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	    }
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
    }

	function playTileset({nextLevel, game, tileset}) {
		if(nextLevel == tileset) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	var played = (bestScore() != null);
	var lastPassed = localStorage.getItem( "guesser-lastPassed");
	var nextLevel = 1;
	if(lastPassed == undefined || lastPassed == null) {
		nextLevel = 1;
	} else {
		nextLevel = Number(lastPassed) + 1;
	}
	var tilesetAvailable = "";
	if(nextLevel < Number(tileset)) {
		tilesetAvailable = "tilesetHidden";
	}


    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => playTileset({nextLevel, game, tileset})} className={'tilesetCard ' + tilesetAvailable} >

                <Card.Body>
                    <Card.Title>LEVEL {tileset}</Card.Title>
                    <Card.Text className="tilesetDate">{date}</Card.Text>
                    <Card.Text><b>{name}</b></Card.Text>

                    {
                        played
                        ? <Card.Text> <img className="gameSmallStar" src={"/images/star.png"}/> {bestScore()} / 100</Card.Text>
                        : nextLevel == Number(tileset) && date != ""

                            ?   <Button href={getUrl({game, tileset})} className="small-btn" variant="primary"
                                onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                            :  ""

					}
                </Card.Body>
            </Card>
        </Col>)

    );
}

function getLevels(game) {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;

	return general[game].filter(tset => tset["date"] <= dateToday).map(tset => ({"id": tset["id"], "name": tset["name"], "date": tset["date"]}));

}



const Levels = ({game, language}) => {
	const { t } = useTranslation();
	var levels = getLevels(game);

	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}

	function getPoints({tileset, game}) {
		tileset = tileset["id"];
		var best = Number(localStorage.getItem(game + "-" + tileset + "-best"));
		if(best == null) {
			return 0;
		} else {
			return best;
		}

	}

	function countPoints(game) {
		var stars = 0;
		var tsets = getLevels(game);

		for(var i = 0; i < tsets.length; i++) {
			var tileset = tsets[i];
			stars += getPoints({tileset, game});
		}
		return stars;
	}


	function getInstruction(game) {
		var inst = {
			"guesser": ""
		}
		return inst[game];
	}

	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Number Guesser - Available Levels</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + game} />
	            <link rel="canonical" href={"https://www.number-guesser.com/" + detectedLanguage + "/levels"} />
	            <meta property="og:url" content={"https://www.number-guesser.com/" + detectedLanguage + "/levels"} />
	            <meta property="og:image" content="https://www.number-guesser.com/banner2.png" />
	            <meta property="og:title" content={"Number Guesser - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>


	        <div className="mainPart">
		        <Container>
		            <GoogleAd type="tilesetsTop"/>
		            <br/>

		            <h1>{bigName(game)}</h1>
					<h2> New level every day! </h2>


		            <h2><img className="biggerStar" src={"/images/star.png"}/> { countPoints(game) + " / " + (100*levels.length) } </h2>

		            {
		                <Row>
		                    {levels.map(tileset => ( <Tileset game={game}  tileset={tileset["id"]}
		                        name={tileset["name"]} date={tileset["date"]} />))}

		                    <Tileset game={game} tileset={levels.length +1} name="New level tomorrow"  date="" />
		                </Row>
		            }

		            <GoogleAd type="tilesetsBottom"/>
		        </Container>
				<br/>
	        </div>

			<Comments/>

        </div>
      );

    return tilesetHtml
}
export default Levels;

