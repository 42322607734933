import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Levels from './components/Levels';
import Moregames from './components/games/Moregames';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'
import logo from './icon.png'

import general from './static/json/general.json';

import { useNavigate } from "react-router-dom";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-F213X4X6PF');

function getNewestTileset() {

	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;

	var levels = general["guesser"].filter(tset => tset["date"] <= dateToday), ts;
	var lastPassed = localStorage.getItem( "guesser-lastPassed");
	var allLevels = levels.length;

	if(lastPassed == undefined || lastPassed == null) {
		return 1;
	} else {
		if(Number(lastPassed)+1 > allLevels) { return "levels"; }
		return Number(lastPassed)+1;
	}

}

function getUrl(lang) {
	var tileset = getNewestTileset();
	return ("/" + lang + "/" + tileset);
}


function MainPage({lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'StartGameMainPage'
	    });
	}



    return (
        <div className="mainPart">
        <Container>
          <Row>
            <><h1>{t('title')}</h1>
    	    <p className="intro">{t('shortInstruction')}</p>
    	    <div><Button variant="success" className="default-btn" href={getUrl(lang)}>{t('play')}</Button>
    	    </div></>


    	    <br/>
    	    <a href={"/" + lang + "/levels"}>Show all levels</a>
          </Row>

        </Container>
        </div>
      );
}

function MoreInfo() {
	const { t } = useTranslation();
	return (
		<>
			<Container className="moreInfo" >
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader1")}</h3>
						<p>{t("gameText1")}</p>
					</Col>

					<Col sm={12}>
						<h3>{t("gameHeader2")}</h3>
						<p>{t("gameText2")}</p>
					</Col>
					<Col sm={12}>
						<h3>{t("gameHeader3")}</h3>
						<p>{t("gameText3")}</p>
					</Col>

				</Row>
			</Container>

			<div className="mainPart">
				<Container className="instructionInfo">
					<Row>
						<Col sm={12}>
							<h3>{t("howToPlay")}</h3>
							<ol>
								<li><b>{t("instruction1title")} </b> <br/> {t("instruction1")}</li>
								<img alt="Word chain game - guess the number" src="/images/instruction/instruction1.png"/>
								<li><b>{t("instruction2title")} </b> <br/> {t("instruction2")}</li>
								<li><b>{t("instruction3title")} </b> <br/> {t("instruction3")}</li>
								<img alt="Word chain game - first guess" src="/images/instruction/instruction2.png"/>
								<p><center>{t("instruction3more")} </center></p>
								<li><b>{t("instruction4title")} </b> <br/> {t("instruction4")}</li>
								<img alt="Word chain game - continue guessing" src="/images/instruction/instruction3.png"/>
								<p><center>{t("instruction4more")} </center></p>
								<li><b>{t("instruction5title")} </b> <br/> {t("instruction5")}</li>
								<img alt="Word chain game - guessed number" src="/images/instruction/instruction4.png"/>
								<li><b>{t("instruction6title")} </b> <br/> {t("instruction6")}</li>

							</ol>
						</Col>
						<div><Button variant="success" className="default-btn" href={getUrl("en")}>{t('play')}</Button>
			            </div><br/>


					</Row>
				</Container>
			</div>

			<Container className="moreInfo" >
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader4")}</h3>
						<p>{t("gameText4")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader5")}</h3>
						<p>{t("gameText5")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader6")}</h3>
						<p>{t("gameText6")}</p>
					</Col>

				</Row>
			</Container>

			<div className="mainPart">
				<Container className="moreInfo" >
					<h3>{t("aboutGame")}</h3>
	                <p>{t("aboutGame1")}</p>
	                <p>{t("aboutGame2")}</p>
	                <h3>{t("aboutGame3title")}</h3>
	                <p>{t("aboutGame3")}</p>
	                <p>{t("aboutGame4")}</p>
	                <p>{t("aboutGame5")}</p>
	                <h3>{t("aboutGame6title")}</h3>
	                <p>{t("aboutGame6")}</p>
                </Container>
			</div>

		</>
	);
}



function Main({lang}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;
	const description = t("description");
	const title = "Number Guesser - " + t("titleShort");

	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.number-guesser.com/" + langBasic} />
                <meta property="og:url" content={"https://www.number-guesser.com/" + langBasic}  data-react-helmet="true" />

                <meta name="description" content={description}/>

                <meta property="og:image" content="https://www.number-guesser.com/banner.png" />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>

            </Helmet>
			<MainPage lang={lang}/>
			<MoreInfo/>

			 <div className="mainPart">
                <Container>
                    <Moregames/>
                </Container>
            </div>
		</>

	);
};

export default Main;
