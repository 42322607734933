import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

const GoogleAd = ({type}) => {
//    useEffect(() => {
//        (window.adsbygoogle = window.adsbygoogle || []).push({});
//    }, []);
//
//	var adSlot;
//
//	var w = window.innerWidth;
//	if(type == "top") {
//		if(w >= 900) {
//			adSlot = "3913619185";
//        } else {
//            adSlot = "9600194166";
//        }
//	}
//	if(type == "bottom") {
//		if(w >= 900) {
//			adSlot = "2811424535";
//        } else {
//            adSlot = "7396487055";
//        }
//	}
//	if(type == "tilesetsTop") {
//		if(w >= 900) {
//			adSlot = "6669728743";
//        } else {
//            adSlot = "9021609668";
//        }
//	}
//	if(type == "tilesetsBottom") {
//		if(w >= 900) {
//			adSlot = "2296419214";
//        } else {
//            adSlot = "1417402064";
//        }
//	}
//	const style = {
//        display: 'block'
//    };
//
//    const containerStyle = {
//        textAlign: 'center'
//    };
//
//	return (
//        <Container style={containerStyle}>
//            <span>Advertisement</span><br/>
//            <ins className="adsbygoogle"
//                style={style}
//                data-ad-client="ca-pub-4607955400949460"
//                data-ad-slot={adSlot}
//                data-ad-format="auto"
//                data-full-width-responsive="true" ></ins>
//        </Container>
//    );
};

export default GoogleAd;