import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet';
import { useState } from 'react';


import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-F213X4X6PF');



const Comments = () => {
	const { t } = useTranslation();
	const [feedbackText, setFeedbackText] = useState('');

	function comment() {

		if(feedbackText != "") {

			var feedbackParts = feedbackText.match(/.{1,80}/g);
			var timestamp = new Date();
			var hour = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();

			for(var i = 0; i < feedbackParts.length; i++) {
				var eventLabel = hour + " " + (i+1).toString() + "/" + (feedbackParts.length).toString() + " " + feedbackParts[i];

				ReactGA.event({
		            category: 'Game',
		            action: 'Feedback',
		            label: eventLabel
		        });
			}
			setFeedbackText("");

			NotificationManager.success(t("thankYou"), "", 3000);

		} else {
			NotificationManager.warning('Empty message!', '', 1200);
		}

	}

	return (
        <Container className="commentsContact">

            <NotificationContainer/>
            <Form.Control as="textarea" rows={3} placeholder={t("writeYourMessage")}
                value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)}/>
            <Button variant="success" className="default-btn" onClick={comment}>{t('comment')}</Button>

        </Container>
    );
}
export default Comments;

