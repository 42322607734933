import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Instruction from './../Instruction';
import Shares from './../Shares';
import general from './../../static/json/general.json';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-F213X4X6PF');


export default function Guesser({view, onGameFinish, chooseTileset, playNextLevel, level}) {

	if(view == "play") {
		var lastPassed = localStorage.getItem("guesser-lastPassed");

		var nextLevel = 1;
		if(lastPassed == undefined || lastPassed == null) {
			nextLevel = 1;
		} else {
			nextLevel = Number(lastPassed) + 1;

			const today = new Date();
			const yyyy = today.getFullYear();
			let mm = today.getMonth() + 1; // Months start at 0!
			let dd = today.getDate();
			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;
			const dateToday = yyyy + "-" + mm + "-" + dd;

			var allLevels = general["guesser"].filter(tset => tset["date"] <= dateToday).length;
			if(nextLevel > allLevels) {
				nextLevel = "levels";
			}
		}
		var url = window.location.href;
		if(nextLevel != level) {
			var newUrl = url.replace(level, nextLevel);
	        window.location.href = newUrl;
		}
	}

	const [attempts, setAttempts] = useState(0);
	const [guesses, setGuesses] = useState([]);

	const hint = general["guesser"][Number(level)-1]["hint"];
	const answer = general["guesser"][Number(level)-1]["answer"];




	const [nextGuess, setNextGuess] = useState("");
	const { t } = useTranslation();

	function getPoints(attempts) {

		var points = [100, 100, 100, 100, 100, 100,
		        80, 70, 60, 50, 40,
		        35, 30, 25, 25, 20,
		        20, 15, 15, 10, 10];
		if(attempts > 20) {
			return 5;
		} else {
			return points[attempts];
		}

	}

	function guess() {
		if(nextGuess == "") { return; }
		var newGuess = Number(nextGuess);
		setGuesses([...guesses, newGuess]);
		setAttempts(attempts+1);
		setNextGuess("");

		if(newGuess == answer) {
			onGameFinish(guesses, getPoints(attempts+1));
		}
	}

	function nothing() {}

	function checkGuess(guess) {
		if(!isNaN(Number(guess))) {
			setNextGuess(guess);
		}
	}

	function getRow(guess) {


		var div = Math.abs(answer - guess);
		var colorFromPalette = Math.floor(50*div/answer);

		var colors = [ "#d52700", "#db3f00", "#e05700", "#e56f00", "#e87b00",
					   "#ea8600", "#ed9200", "#f09e00", "#f3aa00", "#f5b600",
					   "#f8c200", "#face00", "#fdda00", "#ffe500", "#f2ea34",
					   "#e5ee68", "#d8f39c", "#cbf7cf", "#caf0f8", "#ade8f4"  ];

		var color = (colorFromPalette >= 20) ? "#ade8f4" : colors[colorFromPalette];


		var text = "Freezing!";
		if(colorFromPalette <= 1) {text = "Boiling!"}
		else if(colorFromPalette <= 4) {text = "Very hot!"}
		else if(colorFromPalette <= 7) {text = "Hot!"}
		else if(colorFromPalette <= 11) {text = "Warm!"}
		else if(colorFromPalette <= 17) {text = "Cold!"}
		else if(colorFromPalette <= 20) {text = "Very cold!"}
		if(div == 0) {
			text = "Correct!";
			color = "white";
		}

		return (<tr className="guessRow" style={{"backgroundColor": color}}>
			<td style={{"width": "120px"}} >{guess}</td>
			<td style={{"width": "40px"}}>
				{answer < guess
				?   <img className="gameSmallStar" src="/images/arrow-bottom.png"/>
				:   (answer > guess)
                     ?   <img className="gameSmallStar" src="/images/arrow-top.png"/>
                     :   <img className="gameSmallStar" src="/images/success.png"/>
				}
			</td>
			<td >
				{ text }
			</td>
		</tr>);
	}



	const boardCode = (<div className="fullBoard">

			<>
				<h2> LEVEL {level} </h2>
				<h1> Guess {hint}</h1>
				<p>Attempts: {attempts} </p>
				<table className="allGuesses"><tbody>
				{guesses.map(gss => getRow(gss))}
				</tbody></table>

				{ view == "play"
				?   <Row className="guessRowButton">
						<Col sm={8}><Form.Control as="input" className="guessInput" value={nextGuess}
						onKeyPress={(e) => {(e.key === 'Enter' ? guess() : nothing())}}
						onChange={(e) => checkGuess(e.target.value)}/></Col>

						<Col sm={4}><Button variant="success" className="small-btn" onClick={guess}>Guess</Button></Col>
						<br/>
						<br/>
					</Row>
				: <div>

					<br/><h1>{t('win')}</h1>
		            <h4>
		                { "You guessed it in " + attempts + " attempt" + (attempts > 1 ? "s" : "") + " and scored " + getPoints(attempts) + " points!"}
		            </h4>

		            <Button className="default-btn" onClick={playNextLevel}>Play next level </Button><br/>
		            <Button variant="success" className="small-btn" onClick={chooseTileset}>Show all levels</Button>


					<Shares/>

					</div>
				}
			</>

	    </div>
	);

    return boardCode;
}

