import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


const Cookie = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Number Guesser - Cookie Policy</title>
            <meta name="description" content="Cookie policy od number-guesser.com" />
            <link rel="canonical" href="https://www.number-guesser.com/cookie" />
        </Helmet>
        <Container>
            <h2>Rules and regulations for the provision of services via the website www.number-guesser.com</h2>
            
            <ol type="I">
			<li>General Provisions</li>
			<li>Conclusion on the Agreement</li>
			<li>Terms and Conditions for the Provision of Services</li>
			<li>Termination of the Agreement</li>
			<li>Complaints</li>
			<li>Technical Requirements</li>
			<li>Processing of Personal Data</li>
			<li>Modification of the Rules and Regulations</li>
			<li>Final Provisions</li>
			</ol>

			<p class="toleft">These Rules and Regulations (hereinafter: <b>"Rules and Regulations"</b>) sets forth rights and
			obligations related to the use of the website www.number-guesser.com as well as rules for using
			services provided via the website www.number-guesser.com.</p>
			<p class="toleft">Rules and Regulations, in the field of electronic services, constitute the rules and regulations for
			the provision of electronic services, referred to in art. 8 sec. 1(1) of the Polish Act of 18 July 2002
			on the provision of electronic services (Dz.U. [Polish Journal of Laws] of 2002, no. 144, item
			1204, as amended).</p>

			<br/>
			<h4>DEFINITIONS</h4>
			<p class="toleft"><b>Administrator</b> - an entity providing service of access to resources on the Website – Lean House spółka z ograniczoną odpowiedzialnością, ul. Krucza 5/11,
			00-548 Warsaw, entered into the Register of Entrepreneurs of the National Court Register kept by the District Court for the Capital City of Warsaw under KRS no.: 0000718147,
			Tax Identification Number (NIP): 5213813853, Polish National Business Registry Number(REGON): 369500538 , e-mail: contact@quizony.com.</p>
			<p class="toleft"><b>Privacy Policy</b> - a separate document regulating general principles of operation of the Website for all visitors to the Website, including those who are not Users,
			available at the following Internet address: https://www.number-guesser.com/privacy.html</p>
			<p class="toleft"><b>Website </b> - Website www.number-guesser.com managed and administered by the Administrator</p>
			<p class="toleft"><b>Services Services</b> - provided by the Administrator to Users against payment, described in section III of the Rules and Regulations.</p>
			<p class="toleft"><b>User</b> - entity using the services of the Website after accepting the Rules and Regulations.</p>

			<br/>

			<h4 className="bolder">I. General Provisions</h4>
			<ol type="1">
			<li>The Rules and Regulations define in particular the conditions of using the Website, the
			type and scope of the Services, the conditions for the provision of Services, the
			conditions for concluding and terminating agreements for the provision of Services, the
			conditions for the processing of personal data as well as the complaint procedure.</li>
			<li>In connection with the provision of the Services, the Administrator is liable under the
			general rules specified in the Polish Act of 23 April 1964 Civil Code (Dz.U. [Polish Journal
			of Laws] of 1964, no. 16, item 9, as amended).</li>
			<li>The materials contained on the Website and materials shared by the Users within the
			Services, and in particular images, graphics, content of quizzes, layout of these elements
			may be subject to copyright protection and may not be distributed, modified or copied in
			whole or in part, without the prior written consent of the authorised entity (excluding
			the use within fair use, in accordance with the provisions of law).</li>
			<li>User is solely responsible for any infringements of third-party copyrights related to the
			use of the Website.</li>
			<li>Before using the Website, the User is obliged to first accept the provisions contained in
			the Rules and Regulations or the Privacy Policy.</li>
			<li>Acceptance of the Rules and Regulations or the Privacy Policy is done by clicking the field
			"I accept the Rules and Regulations of the Website www.number-guesser.com” or, respectively, "I
			accept the Privacy Policy of the Website www.number-guesser.com”, which will be included in
			the message displayed during the first visit to the Website's home page.</li>
			<li>Only persons over 16 years of age may use the Website.</li>
			<li>Before using the Website, the User is obliged to declare their age. The declaration is made
			by clicking on the field "Yes, I am over 16", which is included in the message displayed
			during the first visit on the main page of the Website.</li>
			</ol>

			<h4 className="bolder">II. Conclusion on the Agreement</h4>
			<ol type="1">
			<li>User uses the Website using the Account or without the necessity to log in.</li>
			<li>Upon acceptance of the Rules and Regulations, the User concludes with the
			Administrator an agreement for the provision of Services offered on the Website, without
			the need to prepare a separate agreement.</li>
			<li>The Rules and Regulations constitute an integral part of agreements concluded with
			Users for the provision of electronic services.</li>
			</ol>

			<h4 className="bolder">III. Terms and Conditions for the Provision of Services</h4>
			<ol type="1">
			<li>Using the Website, the User may use the following Services:
			<ol type="a">
			<li>Creating quizzes;</li>
			<li>Solving quizzes;</li>
			<li>Commenting on and rating quizzes;</li>
			<li>Collecting awards “cups”;</li>
			<li>Sharing quizzes via Messenger, Facebook, Twitter, WhatsApp, Instagram,
			Snapchat and SMS.</li>
			</ol></li>
			<li>The User gets unlimited access to the quizzes they have created. All quizzes are saved
			under the "my quizzes" tab, which contains a list of quizzes with links to them.</li>
			<li>The services provided on the Website are free of charge.</li>
			<li>The Administrator reserves the right to introduce fees for Services provided via the
			Website. The Administrator will notify the User about any changes in payments in the
			form of a message posted on the Website.</li>
			<li>The Administrator reserves the right to change the functionality of the Website at any
			time, in particular by introducing new functions and amenities for Users.</li>
			<li>The User is obliged to use the Services on the terms set out in the Rules and Regulations,
			in accordance with applicable law and the principles of social coexistence.</li>
			<li>The Administrator is not liable for the User's use of the Website in a manner inconsistent
			with the provisions of law, the principles of social coexistence and the provisions of the
			Rules and Regulations.</li>
			<li>The User is prohibited from:
			<ol type="a">
			<li>providing illegal content within the use of the Website and the Services;</li>
			<li>using the Website in a way that interferes with its proper functioning or exposes
			it to damage;</li>
			<li>providing false personal data or information, content or materials of third parties
			without their consent;</li>
			<li>Advertising or promoting own services or goods, as well as services or goods of
			third parties on the Website without the prior consent of the Administrator;</li>
			<li>violating the rights of other Users, in particular through harassment, humiliation
			or intimidation;</li>
			<li>disseminating content that contains computer viruses or other computer files
			that compromise computers or computer networks;</li>
			<li>promoting behaviour that may lead to violation of the law.</li>
			</ol></li>
			<li>The Administrator reserves the right to extend the catalogue included in section 8 above
			for other User behaviour.</li>
			<li>The User grants the Administrator, for an indefinite period of time, a non-exclusive, free
			license to record, copy and distribute the materials posted by the User on the Website, in
			particular the quizzes, in any way, in all fields of use, also for promotional and advertising
			purposes.</li>
			<li>Without prejudice to section 10, the User retains the ownership right to the content
			created by them.</li>
			<li>The Administrator reserves the right to remove the quizzes created by the Users.</li>
			<li>There may be interruptions in the operation of the Website due to technical reasons, in
			particular resulting from the need to repair or maintain it, as well as related to the need
			to create backup copies or process the collected data.</li>
			<li>The Administrator is not responsible for the temporary inability to use the Website,
			caused by technical reasons.</li>
			<li>The Administrator does not guarantee the proper functioning of the Website if the User
			uses hardware and software that do not meet the technical requirements set out in
			Section VI of the Rules and Regulations. Provision of the Service may also be interrupted
			in the event of improper connection quality, damage or defects of telecommunications
			equipment, power systems, computer equipment, failure of the telecommunications
			network or power outages, or any action of third parties.</li>
			<li>The costs of internet connection with the Website are borne by the User in accordance
			with the price list of their telecommunications operator.</li>
			</ol>

			<h4 className="bolder">IV. Termination of the Agreement</h4>
			<ol type="1">
			<li>The user may terminate the agreement with immediate effect, at any time and without
			giving reasons, by sending to the following address: contact@quizony.com an e-mail
			containing a declaration of will to withdraw from the agreement. The User may use the
			withdrawal form, a template of which is attached as Attachment to the Rules and
			Regulations. After receiving the declaration of withdrawal from the agreement to the
			indicated e-mail address, the Administrator will immediately confirm its receipt to the
			User.</li>
			<li>Sending a declaration of will to withdraw from the agreement is also possible via
			traditional mail sent to Lean House spółka z ograniczoną odpowiedzialnością,
			ul. Krucza 5/11, 00-548 Warsaw, Poland. The Administrator will send
			confirmation of receipt of the declaration of withdrawal from the agreement to the
			mailing address provided by the User.</li>
			<li>Regardless of the right to withdraw from the agreement, the User is entitled to resign
			from using the Website at any time by ceasing to use the Services. Ending the use of the
			Website does not result in the loss of access to the created quizzes.</li>
			<li>The Administrator reserves the right to delete the quizzes of a User who does not comply
			with the provisions of the Rules and Regulations, acts in a manner inconsistent with
			generally applicable law or the principles of social coexistence, or when their behaviour
			is considered harmful to the Administrator or other Users. Deleting quizzes means the
			termination of the agreement.</li>
			<li>For important reasons, the Administrator may terminate the Agreement for the provision
			of Services with the User. Important reasons are, in particular, significant economic or
			business reasons and the termination of business activity by the Administrator.</li>
			<li>The Administrator will inform the User about the termination of the agreement 14 days
			in advance by means of a message displayed on the main page of the Website.</li>
			<li>In the event of termination of the provision of Services for reasons attributable to the
			User or the Administrator, the User is not entitled to compensation.</li>
			</ol>

			<h4 className="bolder">V. Complaints</h4>
			<ol type="1">
			<li>The User has the right to lodge a complaint regarding the provision of Services under the
			Rules and Regulations at any time.</li>
			<li>the complaint may concern in particular:
			<ol type="a">
			<li>no access to the User Account;</li>
			<li>provision of Services by the Administrator in violation of the Rules and Regulations;</li>
			<li>errors in the functioning or availability of the Services.</li>
			</ol></li>
			<li>The complaint may be sent by e-mail at the following address: contact@quizony.com or
			in writing at the address of the Administrator’s seat.</li>
			<li>The complaint should contain the User's designation and a detailed description of the
			circumstances underlying the complaint.</li>
			<li>The Administrator will consider the complaint within 14 days of its receipt. If the
			complaint cannot be examined within this time limit, the Administrator shall notify the
			person submitting the complaint about the reasons for the delay and the expected date of
			considering the complaint, the total time limit may not be longer than 30 days.</li>
			<li>A response to the complaint is sent to the e-mail or mailing address provided by the User,
			if requested by the User when submitting the complaint.</li>
			</ol>


			<h4 className="bolder">VI. Technical Requirements</h4>
			<ol type="1">
			<li>For the uninterrupted use of the Website and the Services, it is necessary for the User to
			meet the technical requirements referred to in this section of the Rules and Regulations.</li>
			<li>For the proper use of the Website, it is necessary to have a computer or other device
			connected to the Internet with a correctly installed and configured web browser, e.g .:
			<ol type="a">
			<li>Mozilla Firefox,</li>
			<li>Microsoft Internet Explorer,</li>
			<li>Opera,</li>
			<li>Google Chrome,</li>
			<li>Safari.</li>
			</ol></li>
			<li>It is also necessary to have Cookies and Java Script enabled and a minimum screen
			resolution of 640x480.</li>
			</ol>


			<h4 className="bolder">VII. Processing of Personal Data</h4>
			<ol type="1">
			<li>Administrator of Users' personal data processed for the purpose of using the Services
			provided through the Website and for marketing, contact, archiving and complaint
			purposes is the Administrator - Lean House spółka z ograniczoną odpowiedzialnością,
			ul. Krucza 5/11, 00-548 Warsaw, entered into the Register of
			Entrepreneurs of the National Court Register kept by the District Court for the Capital
			City of Warsaw under KRS no.: 0000718147, Tax Identification Number (NIP):
			5213813853, Polish National Business Registry Number (REGON): 369500538, e-mail:
			contact@quizony.com.</li>
			<li>The Administrator ensures the implementation of the requirements resulting from
			Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April
			2016 on the protection of individuals with regard to the processing of personal data and
			on the free movement of such data, and repealing Directive 95/46/EC ( General Data
			Protection Regulation) (hereinafter: “GDPR”).</li>
			<li>Contact with the Administrator is possible:
			a. via post - at the following address: ul. Krucza 5/11, 00-548 Warsaw, Poland or
			b. via e-mail - at the following e-mail address: contact@quizony.com</li>
			<li>Users' personal data in the form of designation of a given User (user name, e-mail
			address) and data that have been made available to the Administrator by Facebook,
			Twitter and WhatsApp will be processed for the purpose of:
			a. Concluding the agreement between the User and the Administrator for the
			provision of Services offered on the Website and its use - pursuant to art. 6 sec.
			1(b) of GDPR, i.e. processing is necessary to conclude and perform an agreement
			(the subject matter of which is the execution of services provided by the
			Administrator) to which the User is a party or taking action at the User's request
			before concluding the agreement;
			b. marketing of the Administrator's own goods and services, including
			segmentation of consumer behaviour, for analytical and profiling purposes -
			pursuant to art. 6 sec. 1(f) of GDPR, i.e. based on the legitimate interest pursued
			by the Administrator, which is direct marketing of their services;
			c. marketing of the Administrator's own goods and services, including
			segmentation of consumer behaviour, for analytical and profiling purposes -
			pursuant to art. 6 sec. 1(a) of GDPR, i.e. based on the voluntary consent of the
			User;
			d. establishing, pursuing or defending any claims between the User and the
			Administrator - pursuant to art. 6 sec. 1(f) of GDPR, i.e. based on the legitimate
			interest pursued by the Administrator, which is the possibility of pursuing claims
			or defending against claims;
			e. taking possible actions in connection with counteracting crimes - pursuant to art. 6
			sec. 1(f) of GDPR, i.e. on the basis of the legitimate interest pursued by the
			Administrator, which is the ability to prevent and prosecute crimes committed to
			the detriment of the Administrator.</li>
			<li>Providing personal data by the User is voluntary, however, without providing it, the User
			may not use all the Services indicated in Section III(1).</li>
			<li>Users' personal data will be processed for the period necessary to achieve the purposes
			for which the data is processed or until an objection is raised (if the basis for processing
			is the legitimate interest of the Administrator) - depending on which of the events occurs
			first. Later, the Administrator will store it until any claims are time-barred and for a
			period of two (2) months after the end of such applicable limitation period.</li>
			<li>For Users whose personal data is processed by the Administrator, decisions will be made
			in an automated manner, including as a result of profiling.</li>
			<li>User has:
			<ol type="a">
			<li>the right to access their personal data;</li>
			<li>the right to request its rectification;</li>
			<li>the right to demand its removal;</li>
			<li>the right to request the restriction of its processing;</li>
			<li>the right to transfer personal data, i.e. to receive personal data from the
			Administrator in a structured, commonly used machine-readable format. The
			User may also request the Administrator to send their personal data they
			provided to another administrator;</li>
			<li>the right to withdraw consent to the extent that it is the basis for the processing
			of personal data. Withdrawal of consent does not affect the lawfulness of the
			processing that was made on the basis of consent before its withdrawal;</li>
			<li>the right to object to the processing of personal data to the extent that the basis
			for the processing of personal data is the condition for the legitimate interest of
			the Administrator.</li>
			</ol>
			In order to exercise the above rights, one should contact the Administrator, using the
			contact details indicated in section 3.</li>
			<li>Users' personal data will be disclosed to the following entities: employees and associates
			of the Administrator, IT service providers, hosting providers, advertisers, entities
			providing advisory and legal services, to the extent necessary to perform an agreement
			between the Administrator and this entity.</li>
			<li>Users' personal data may also be made available to entities and bodies authorised to
			process such data on the basis of legal provisions.</li>
			<li>Administrator may transfer Users' personal data to countries outside the European
			Economic Area (EEA).</li>
			<li>The User has the right to lodge a complaint with the supervisory body dealing with the
			protection of personal data - the President of the Office for Personal Data Protection, if
			the User believes that the processing of data by the Administrator violates the GDPR.</li>
			<li>The Inspector of Personal Data Protection processed by the Website is Lean House sp. z o.o.</li>
			<li>The Website uses cookies - more information in this regard can be found in the Privacy
			Policy on the website https://www.number-guesser.com/privacy.html.</li>
			<li>All other issues related to the protection of personal data are included in the Privacy
			Policy.</li>
			</ol>

			<h4 className="bolder">VIII. Modification of the Rules and Regulations</h4>

			<ol type="1">
			<li>The Administrator has the right to unilaterally modify the provisions of the Rules and
			Regulations if at least one of the important reasons indicated in the catalogue below
			occurs:
			<ol type="1">
			<li>Amendment to the provisions of applicable law that regulates the conduct of
			business by the Administrator;</li>
			<li>The need to adapt the wording of the Rules and Regulations to applicable law or
			the need to make editorial changes to the Rules and Regulations;</li>
			<li>Modification of the operation or functioning of the website www.number-guesser.com
			or individual Services, which will be caused by objective and independent
			reasons of technological or technical nature;</li>
			<li>Modification of the terms of use of the website www.number-guesser.com or individual
			Services, not deteriorating the situation of Users compared to the existing ones;</li>
			<li>The need to update the Administrator's data indicated in the Rules and
			Regulations.</li>
			</ol>
			</li>
			<li>The modifications come into force when the amended text of the Rules and
			Regulations is posted on the website: https://www.number-guesser.com/terms.html.</li>
			<li>The Administrator shall notify the User of any modification of the Rules and
			Regulations 14 days in advance by means of a message displayed on the Website.</li>
			<li>The User may resign from using the Service at any time, in accordance with the
			principles set out in Section IV of the Rules and Regulations, if they do not accept the
			wording of the new version of the Rules and Regulations.</li>
			</ol>

			<h4 className="bolder">IX. Final Provisions</h4>
			<ol type="1">
			<li>The Rules and Regulations are valid from 15-09-2021.</li>
			<li>The Regulations are available to Users at any time, free of charge on the website
			https://www.number-guesser.com/terms.html and at the Administrator's office.</li>
			<li>The User may receive technical support in connection with the functioning of the
			Website at the e-mail address: contact@quizony.com</li>
			<li>In matters not covered by the Rules and Regulations, generally applicable provisions
			of Polish law shall apply.</li>
			<li>The choice of Polish law on the basis of the Rules and Regulations does not deprive
			the User who is a consumer of the protection granted to them on the basis of
			provisions that cannot be excluded by agreement between the Administrator and the
			User, under the law which, in accordance with the relevant regulations, would be
			applicable in the case of the law of choice.</li>
			<li>None of the provisions of the Rules and Regulations limits the consumer's rights
			under applicable law.</li>
			<li>Detailed information on the possibility for the User who is a consumer to use out-of-
			court complaint and redress methods and the rules of access to these procedures are
			available on the website of the Polish Office of Competition and Consumer Protection
			at: https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php.</li>
			<li>There is also a contact point at the President of the Office of Competition and
			Consumer Protection (telephone: 22 55 60 333, e-mail: kontakt.adr@uokik.gov.pl or
			in writing at: Pl. Powstańców Warszawy 1, 00-030 Warsaw.), whose task is, inter alia,
			to assist consumers in matters relating to out-of-court resolution of consumer
			disputes.</li>
			<li>The consumer has the following exemplary possibilities of using extrajudicial means
			of dealing with complaints and redress: (1) application for dispute resolution to a
			permanent consumer arbitration court (more information at: http://
			www.spsk.wiih.org.pl/); (2) application for an out-of-court settlement of the dispute
			to the voivodeship inspector of the Trade Inspection (more information on the
			website of the inspector competent for the place of business by the Service Provider);
			and (3) assistance of a poviat (municipal) consumer ombudsman or a social
			organization statutory tasks of which include consumer protection (including the
			Consumer Federation, Association of Polish Consumers). Advice is provided, inter alia,
			via e-mail at tips@dlakonsumentow.pl and at the consumer helpline number 801 440
			220 (the helpline is available on Business Days, between 8:00 a.m. and 06:00 p.m.,
			connection fee according to the operator's tariff).</li>
			<li>Pursuant to Regulation (EU) No 524/2013 of the European Parliament and of the
			Council of 21 May 2013 on on-line dispute resolution for consumer disputes and
			amending Regulation (EC) No 2006/2004 and Directive 2009/22/EC (Regulation on
			ODR in consumer disputes), the User may submit a complaint in order to resolve any
			dispute with the Administrator via the ODR internet platform (the ODR platform is
			available at - http://ec.europa.eu/consumers/odr/).</li>
			</ol>


			<br/>
			<h4 class="bolder">Attachment to the Rules and Regulations</h4>
			<p class="toleft">TEMPLATE FORM FOR WITHDRAWING FROM THE AGREEMENT FOR THE USE OF THE WEBSITE SERVICES <br/><br/>

			Addressee: Lean House spółka z ograniczoną odpowiedzialnością, ul. Krucza 5/11, 00-548 Warsaw, Poland<br/>
			e-mail: contact@quizony.com<br/>
			I _______________________________ hereby inform about the withdrawal from the agreement for the use of the Services of the Website www.number-guesser.com.<br/>
			User's Full name: _______________________________<br/>
			User’s e-mail: _______________________________<br/>
			User’s mailing address*: ___________________________<br/>
			User’s signature and Date*: ___________________________ <br/>
			<br/>

			* fill in when sending the form via traditional mail.

			</p>

        </Container>
        </div>
      );

    return tilesetHtml
}

export default Cookie;

