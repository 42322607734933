import React from 'react';
//import ReactDOM from 'react-dom/client';
//import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom';

import './style.css';

import Game from './Game';
import Main from './Main';
import HowToPlay from './components/HowToPlay';
import Minigame from './components/games/Minigame';
import PlayGame from './components/games/PlayGame';
import Header from './components/Header';
import Footer from './components/Footer';
import Levels from './components/Levels';
import ZestawySpodLady from './components/ZestawySpodLady';
import general from './static/json/general.json';

import CookiePolicy from './components/CookiePolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Contact from './components/Contact';
import OurGames from './components/OurGames';

import './i18n';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-F213X4X6PF');


function Root() {

	const languages = ["en"];
    const games = [];
    const levels = general["guesser"].map(tset => tset.id);

	var url = window.location.href;

	if(!url.includes("www") && url.includes("number")) {
		var newUrl = url;
		newUrl = newUrl.replace("number", "www.number");
		if(url != newUrl) {

			window.location.href = newUrl;
		}
	}

	return (
		<React.StrictMode >
			<Header/>
			<Router>
				<div className="App">
	            <Routes>
	                    <Route path="/" element={<Main lang="en" />} />
	                    <Route path="/de" element={<Main lang="de" />} />
	                    <Route path="/it" element={<Main lang="it" />} />
	                    <Route path="/fr" element={<Main lang="fr" />} />

	                    <Route path="/howtoplay" element={<HowToPlay />} />
	                    <Route path="/zestawy_spod_lady" element={<ZestawySpodLady />} />

	                    <Route path="/cookies" element={<CookiePolicy />} />
	                    <Route path="/privacy" element={<PrivacyPolicy />} />
	                    <Route path="/terms" element={<Terms />} />
	                    <Route path="/contact" element={<Contact />} />

						{/*TILES*/}
				        {languages.map(lang => (
				            <Route key={lang} path={`/${lang}/levels`}
					                element={<Levels game="guesser" lang={lang} />}
					        />
				            )
				        )}
				        {languages.map(lang =>
				            levels.map(tileset => (
				                <>
					                <Route
					                    key={lang}
					                    path={`/${lang}/${tileset}`}
					                    element={<Game game="guesser" lang={lang} tileset={tileset} />}
					                />

				                </>
				            ))
				        )}


				        {languages.map(lang =>
				            games.map(game => (
				                <>
						            <Route key={lang} path={`/${lang}/${game}`}
							                element={<Minigame lang={lang} game={game} />}
							        />
						            <Route key={lang} path={`/${lang}/${game}/levels`}
							                element={<Levels lang={lang} game={game} />}
							        />

						            {general[game].map(tileset => (
						                <>
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}`}
							                    element={<Game game={game} lang={lang} tileset={tileset} />}
							                />
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}/play`}
							                    element={<PlayGame lang={lang} game={game} tileset={tileset} />}
							                />
						                </>
						            ))}

						        </>
				            ))
				        )}



				        {languages.map(lang => (
				            <>
				            <Route key={lang} path={`/${lang}/circles`}
						        element={<Minigame lang={lang} game={"circles"} />}
						        />
						    <Route key={lang} path={`/${lang}/circles/small`}
						        element={<Game game={"circles"} lang={lang} tileset={"small"} />}
						        />
						    </>
						    ))
				        }
	                    <Route path="/*" element={<Main lang="en" />} />
	            </Routes>


            </div>
			</Router>
			<Footer/>

		</React.StrictMode>

	);
};


ReactDOM.render(<Root />, document.getElementById('root'));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Root />, rootElement);
} else {
  ReactDOM.render(<Root />, rootElement);
}
