import React from 'react';

import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import '../../static/css/tilesets.css';

import { useNavigate } from "react-router-dom";

import general from '../../static/json/general.json';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-F213X4X6PF');

function MainPage({game, lang, tileset}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'StartMiniGame'
	    });
	}

	function getUrl() {
		if(game != "tiles") {
			return "/" + lang + "/" + game + "/" + tileset;
		} else {
			return "/" + lang + "/" + tileset;
		}

	}

	const title = t(game + "Title");
	const shortInstruction = t(game + "Instruction");

    return (
        <div className="mainPart">
        <Container>
          <Row>
            <><h1>{title}</h1>
    	    <p className="intro">{shortInstruction}</p>
    	    <div><Button href={getUrl()} variant="success" className="default-btn" onClick={play}>{t('play')}</Button>
    	    </div>
    	    <a href={"/" + lang + "/" + game + "/tilesets"}>{t('chooseTileset')}</a>
    	    </>

          </Row>

        </Container>
        </div>
      );
}


function MoreInfo({game}) {
	const { t } = useTranslation();
	return (
		<>
			<Container className="moreInfo" >
				<Row>
					<Col sm={9}>
						<h3>{t(game + "Header1")}</h3>
						<p>{t(game + "Text1")}</p>
					</Col>
					<Col sm={3}><img alt="Tile1" src={"/images/games/" + game + "/pic1.png"} /></Col>
				</Row>
				<Row>
					<Col sm={3}><img alt="Tile2" src={"/images/games/" + game + "/pic2.png"}  /></Col>
					<Col sm={9}>
						<h3>{t(game + "Header2")}</h3>
						<p>{t(game + "Text2")}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<h3>{t(game + "Header3")}</h3>
						<p>{t(game + "Text3")}</p>
					</Col>
					<Col sm={3}><img alt="Tile3" src={"/images/games/" + game + "/pic3.png"} /></Col>
				</Row>
			</Container>

			<Container className="moreIconTiles">
	            <Row>
	                <img alt="Tile1" src={"/images/games/" + game + "/pic1.png"} />
	                <img alt="Tile2" src={"/images/games/" + game + "/pic2.png"} />
	                <img alt="Tile3" src={"/images/games/" + game + "/pic3.png"} />
	            </Row>
	        </Container>
		</>
	);
}

function Main({lang, game, tileset}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;

	React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);

    const title = t(game + "Title");

	return (
		<>
			<Helmet>
                <title>{ title }</title>
                <link rel="canonical" href={"https://www.number-guesser.com/" + lang + "/" + game + "/" + tileset + "/play"} />
                {/*<link rel="canonical" href={"https://www.number-guesser.com/" + lang + "/" + game} /> */}
                <meta property="og:image" content={"https://www.number-guesser.com/images/games/" + game + "/" + tileset + ".png"} />
            </Helmet>
			<MainPage game={game} lang={lang} tileset={tileset}/>
			<br/><br/>


			<MoreInfo game={game}/>
		</>

	);
};

export default Main;
